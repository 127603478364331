$(document).ready(function() {
  if($('body').is('body.outgoing_document_shares')) {
    // hide the upload fallback to prevent it from being visible while dropzone initializes
    $('div#nojs-upload-fallback').hide();
    import(/* webpackChunkName: "dropzone" */ 'dropzone').then(dropzone => {
      dropzone.options.documentUploadDropzone = {
        createImageThumbnails: false,
        maxFilesize: 20,
        addRemoveLinks: true,
        acceptedFiles: '.pdf, .jpg, .jpeg, .png, .xls, .xlsx, .csv, .doc, .docx, .eml, .txt',
        dictCancelUpload: '',
        dictCancelUploadConfirmation: 'Upload wirklich abbrechen?',
        dictFallbackMessage: 'Ihr Browser unterstützt keine Uploads per Drag & Drop.',
        dictInvalidFileType: 'Ungültiges Dateiformat',
        dictRemoveFile: 'Entfernen',
        init: function() {
          this.on('success', function(file) {
            this.removeFile(file);
          });
          this.on('queuecomplete', function() {
            $.getScript("new?document_share[to_id]="+$('input#document_share_to_id').val());
          });
        }
      }
    });
  }
});
