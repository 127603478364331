# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

update_guild_select = ->
  branch = $('select#account_main_branch :selected').text()
  if branch is 'Schornsteinfeger'
    $('#guild_form_group').show(400)
  else
    $('#guild_form_group').hide(400)
    $('#account_main_guild_member_false').prop('checked', true)

check_enable_guild = ->
  guild_member = $("input[name='account_main[guild_member]']:checked").val() is 'true'
  $('select#account_main_guild').prop('disabled', not guild_member)

$(document).on 'ready page:load', ->
  if $('body').is('body.account_main')
    update_guild_select()
    $('select#account_main_branch').change update_guild_select

    check_enable_guild()
    $("input[name='account_main[guild_member]']").change check_enable_guild
