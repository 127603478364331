/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../src/vendor/plugin_detect_pdf_reader.min.js';

import '../src/account/contract_templates.js.coffee';
import '../src/account/contracts.js.coffee';
import '../src/account/cover_templates.js.coffee';
import '../src/account/invitations.js.coffee';
import '../src/account/main.js.coffee';

import '../src/admin/organization_merger.js.coffee';
import '../src/admin/organizations.js.coffee';

import '../src/comments.js.coffee';
import '../src/communications.js.coffee';
import '../src/documents.js.coffee';
import '../src/home.coffee';
import '../src/info.coffee';
import '../src/interactions.js.coffee';
import '../src/orders.js.coffee';
import '../src/post_document_upload.js';
import '../src/share_document_upload.js';
import '../src/shared.js.coffee';
import '../src/stripe_payments.js.coffee';

require("jquery");
require("trix");
require("@rails/actiontext");
require("bootstrap-datepicker");
require("bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min");
require("eonasdan-bootstrap-datetimepicker");

import Rails from "@rails/ujs";
Rails.start();

