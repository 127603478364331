$(document).ready ->
  forms = $('form.new_comment')

  # disable all submit buttons initially
  forms.find('.input-group-btn > .btn').prop('disabled', true)

  # enable submit button when text was entered
  $('input[name="comment[body]"]').on 'keyup change blur', ->
    button = $(this).parent().find('.input-group-btn > .btn')

    if (this.value == '')
      button.prop('disabled', true)
    else
      button.prop('disabled', false)
