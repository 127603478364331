exports.clone_form_group = (form_group, replacements) => {
  const clone = $(form_group).clone()
  for (const finder in replacements) {
    const replacement = replacements[finder];
    switch (finder) {
      case 'input':
        clone.find('input').val(replacement);
        break;
      case 'label':
        clone.find('label').text('Zusatzposition');
    }
  }
  clone.insertAfter(form_group);
};
