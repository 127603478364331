update_additional_info_select = ->
  private_info_kind = $('select#account_cover_template_private_info_kind :selected').text()
  company_info_kind = $('select#account_cover_template_company_info_kind :selected').text()
  if private_info_kind == 'Zusatzinfo' || company_info_kind == 'Zusatzinfo'
    $('.additional-form-group').show(400)
  else
    $('.additional-form-group').hide(400)

normal_action = ''
preview_action = ''
disable_text = ''

normal_submit = ->
  $('#cover-template-form').removeAttr('target')
  $('#cover-template-form').attr('action', normal_action)
  $('#cover-template-form :submit').attr('data-disable-with', disable_text)
  $('#cover-template-form').submit()

preview_submit = (preview_kind) ->
  $('#cover-template-form').attr('target', '_blank')
  $('#cover-template-form').attr('action', preview_action + '?preview_kind=' + preview_kind)
  $('#cover-template-form :submit').removeAttr('data-disable-with')
  $('#cover-template-form').submit()

$(document).on 'ready page:load', ->
  if $('body').is('body.account_cover_templates')
    update_additional_info_select()
    $('select#account_cover_template_private_info_kind').change update_additional_info_select
    $('select#account_cover_template_company_info_kind').change update_additional_info_select

    normal_action = $('#cover-template-form').attr('action')
    preview_action = normal_action + '/preview'
    disable_text = $('#cover-template-form :submit').attr('data-disable-with')

    $('#submit-button').click ->
      normal_submit()
    $('#submit-link-private').click ->
      preview_submit('private')
    $('#submit-link-company').click ->
      preview_submit('company')
