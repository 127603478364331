update_submit_button = ->
  self_print_tool_downloaded = $('input#self_print_tool_downloaded').length == 0 || $('input#self_print_tool_downloaded').is(":checked")
  dbrief_printer_downloaded = $('input#dbrief_printer_downloaded').length == 0 || $('input#dbrief_printer_downloaded').is(":checked")
  $('input#submit_button').attr('disabled', !(self_print_tool_downloaded && dbrief_printer_downloaded) ? 'disabled' : '')

$(document).on 'ready page:load', ->
  if $('body').is('body.info')
    update_submit_button()
    $('input#self_print_tool_downloaded').change update_submit_button
    $('input#dbrief_printer_downloaded').change update_submit_button
