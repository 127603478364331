$(document).on 'ready page:load', ->
  if $('body').is('body.account_contracts')
    $('form#new_account_contract').find('select#contract_template_version_id').change ->
      contract_values = $('form#new_account_contract :input[name^="account_contract["]').serializeArray()
      redirect_path = '/account/contract_template_versions/' + $(this).val() + '/contracts/new?'
      reducer = (memo, parameter) ->
        memo.push(parameter.name + '=' + parameter.value)
        memo
      redirect_path += contract_values.reduce(reducer, []).join('&')
      window.location.replace(redirect_path)
