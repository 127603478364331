$(document).on 'ready page:load', ->
  if $('body').is('body.account_contract_templates')
    update_remuneration_select = ->
      remuneration = $('select#account_contract_template_remuneration').val()
      switch remuneration
        when 'civil_code'
          $('div#remuneration_civil_code').show()
          $('div#remuneration_automatic_price_adjustment').hide()
          $('div#remuneration_announced_price_adjustment').hide()
          $('div#form_group_hourly_rate').hide()
        when 'automatic_price_adjustment'
          $('div#remuneration_civil_code').hide()
          $('div#remuneration_automatic_price_adjustment').show()
          $('div#remuneration_announced_price_adjustment').hide()
          $('div#form_group_hourly_rate').show()
        when 'announced_price_adjustment'
          $('div#remuneration_civil_code').hide()
          $('div#remuneration_automatic_price_adjustment').hide()
          $('div#remuneration_announced_price_adjustment').show()
          $('div#form_group_hourly_rate').show()

    update_automatically_send_select = ->
      automatically_send = $('select#account_contract_template_automatically_send_once').val()
      if automatically_send == 'true'
        $('.include-multiple-form-group').show()
      else
        $('.include-multiple-form-group').hide()

    $('select#account_contract_template_remuneration').change update_remuneration_select
    update_remuneration_select()
    $('select#account_contract_template_automatically_send_once').change update_automatically_send_select
    update_automatically_send_select()
