$(document).on 'ready page:load', ->
  if $('body').is('body.public-home#index')
    el = $('#index .flickity-carousel')
    if el.flickity
      el.flickity({
        pageDots: false,
        prevNextButtons: false,
        autoPlay: true,
        wrapAround: true
      })
