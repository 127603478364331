import accounting from './vendor/accounting.min.js'

# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
$(document).on 'ready page:load', ->
  if $('body').is('body.documents') or $('body').is('body.outgoing_post_assignments') or $('body').is('body.outgoing_sent_post_assignments') or $('body').is('body.outgoing_unsent_post_assignments')
    add_new_building_handler = (e) ->
      if $('input#owner_role').prop('checked') && !$('input#manager_role').prop('checked')
        e.preventDefault()
        $('div#manager_confirmation_modal').modal('show')

    $('form#new_building').submit add_new_building_handler

    add_building_handler = (e) ->
      $('input#building_street_name').val(e.target.dataset.building_street_name)
      $('input#building_street_number').val(e.target.dataset.building_street_number)
      $('input#building_postal_code').val(e.target.dataset.building_postal_code)
      $('input#building_city').val(e.target.dataset.building_city)
      $('form#new_building').submit()

    set_manager_role_and_submit = (value) ->
      $('form#new_building').unbind 'submit', add_new_building_handler
      $('span[name=add_building]').unbind 'click', add_building_handler
      $('button#owner_is_manager_confirm').unbind 'click', set_manager_role_and_submit
      $('button#owner_is_manager_decline').unbind 'click', set_manager_role_and_submit
      $('input#manager_role').prop('checked', value)
      $('form#new_building').submit()
      $('form#new_building').submit (e) -> e.preventDefault()
      $('div#manager_confirmation_modal').modal('hide')

    $('button#owner_is_manager_confirm').click (e) ->
      set_manager_role_and_submit true

    $('button#owner_is_manager_decline').click (e) ->
      set_manager_role_and_submit false

    $('span[name=add_building]').click add_building_handler

    update_tax_and_gross_amount = ->
      net_amount = accounting.parse($('#document_invoice_attributes_net_amount').val(), ',')
      tax_percentage = accounting.parse($('#document_invoice_attributes_tax_percentage').val(), ',')
      tax_amount = (net_amount * tax_percentage).toFixed(2)
      gross_amount = (Number(net_amount) + Number(tax_amount)).toFixed(2)
      $('#document_invoice_attributes_tax_amount').val(accounting.formatNumber(tax_amount, 2, '.', ','))
      $('#document_invoice_attributes_gross_amount').val(accounting.formatNumber(gross_amount, 2, '.', ','))

    $('#document_invoice_attributes_net_amount').change (e) ->
      update_tax_and_gross_amount()

    $('#document_invoice_attributes_tax_percentage').change (e) ->
      update_tax_and_gross_amount()

    $('#document_kind').change (e) ->
      invoice_diff = $('#edit_invoice_fields')
      if e.target.value == 'invoice'
        invoice_diff.removeClass('hidden')
      else
        invoice_diff.addClass('hidden')

    $('#show-kind-parsing-failed-form').click (e) ->
      $('#report-document-info').hide()
      $('#document-kind-form').show()

    $('#hide-kind-parsing-failed-form').click (e) ->
      $("#report-document-info").show()
      $("#document-kind-form").hide()

    $('#show-invoice-data-parsing-failed-form').click (e) ->
      $('#report-document-info').hide()
      $('#document-invoice-data-form').show()

    $('#hide-invoice-data-parsing-failed-form').click (e) ->
      $("#report-document-info").show()
      $("#document-invoice-data-form").hide()

    $('#show-building-parsing-failed-form').click (e) ->
      $('#document-building-info').hide()
      $('#document-building-form').show()

    $('#hide-building-parsing-failed-form').click (e) ->
      $("#document-building-info").show()
      $("#document-building-form").hide()
