$(document).on 'ready page:load', ->
  if $('body').is('body.account_invitations')
    swich_invitation_forms = (show_id, hide_id) ->
      $(show_id).removeClass 'hidden'
      $(hide_id).addClass 'hidden'

    $('#showActivation').click (e) ->
      swich_invitation_forms '#activationFormContainer', '#emailFormContainer'

    $('#showEmailRegistration').click (e) ->
      swich_invitation_forms '#emailFormContainer', '#activationFormContainer'
