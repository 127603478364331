# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

update_guild_select = ->
  is_schornsteinfeger = $('select#organization_branch :selected').text() == 'Schornsteinfeger'
  $('select#organization_guild').prop 'disabled', !is_schornsteinfeger

$(document).on 'ready page:load', ->
  if $('body').is('body.admin_organizations')
    update_guild_select()
    $('select#organization_branch').change update_guild_select
