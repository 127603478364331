$(document).on 'ready page:load', ->
  if window.Stripe == undefined
    return

  $("form[id^=payment-form-]").each (idx, payment_form) ->
    payment_form = $(payment_form)
    payment_request_id = payment_form[0].id.match(/-(\d+)$/)[1]
    stripe = Stripe($('meta[name="stripe_key"]').attr('content'), {stripeAccount: payment_form.find('input#stripe_sender').val()})

    elements = stripe.elements()

    style =
      base:
        color: '#32325d'
        lineHeight: '18px'
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif'
        fontSmoothing: 'antialiased'
        fontSize: '16px'
        '::placeholder':
          color: '#aab7c4'
      invalid:
        color: '#fa755a'
        iconColor: '#fa755a'

    # initiate card payment element
    card = elements.create('card', {style: style, hidePostalCode: true})
    card_element_selector = '#card-element-' + payment_request_id
    card.mount(card_element_selector) if payment_form.find(card_element_selector).length

    # listen for payment method toggling
    payment_method = payment_form.find('#payment_method :input')
    payment_form.find('#payment_method_info').addClass(payment_method.val())
    payment_method.on 'change', (event) ->
      payment_method_info = payment_form.find('#payment_method_info')
      payment_method_info.removeClass('sofort')
      payment_method_info.removeClass('card')
      payment_method_info.addClass(this.value)

    # submit payment
    payment_form.on 'submit', (event) ->
      event.preventDefault()
      submit_button = payment_form.find('input#payment-form-submit')
      submit_button.prop 'disabled', true

      payment = payment_form.find('input[name=payment]:checked').val()

      if payment == 'card'
        client_secret = submit_button.data('secret')
        cardData =
          payment_method_data: {
            billing_details: {name: payment_form.find('input#name').val()}
          }

        stripe.handleCardPayment(client_secret, card, cardData).then (result) ->
          if result.error
            handleError result.error
            submit_button.prop 'disabled', false
          else
            hiddenInput = $('<input>')
            hiddenInput.attr 'type', 'hidden'
            hiddenInput.attr 'name', 'payment_intent_id'
            hiddenInput.attr 'value', result.paymentIntent.id
            payment_form.append hiddenInput
            payment_form.find('input#payment-form-submit').prop 'disabled', false
            payment_form.unbind().submit()

      if payment == 'sofort'
        sourceData =
          type: payment
          amount: parseInt(payment_form.find('input#gross_amount').val())
          currency: 'eur'
          redirect:
            return_url: window.location.href
          sofort:
            country: 'DE'
          owner:
            name: payment_form.find('input#name').val()
            email: payment_form.find('input#email').val()
            address:
              line1: payment_form.find('input#address').val()

        stripe.createSource(sourceData).then (result) ->
          if result.error
            handleError result.error
            submit_button.prop 'disabled', false
          else
            hiddenInput = $('<input>')
            hiddenInput.attr 'type', 'hidden'
            hiddenInput.attr 'name', 'stripeIdentifier'
            hiddenInput.attr 'value', result.source.id
            payment_form.append hiddenInput
            payment_form.find('input#payment-form-submit').prop 'disabled', false
            payment_form.unbind().submit()

    handleError = (error) ->
      errorElement = payment_form.find('#payment-form-errors')
      if error.type == 'validation_error'
        errorElement.text error.message
      else
        errorElement.text 'Es ist ein Fehler aufgetreten. Der Betrag wurde nicht überwiesen'
      errorElement.addClass('visible')

  # payment confirmation
  if $('div#payment_confirmation_messages').length

    # fetch get params from external redirect
    getParams = ->
      query = window.location.search.substring(1)
      raw_vars = query.split("&")
      params = {}
      for v in raw_vars
        [key, val] = v.split("=")
        params[key] = decodeURIComponent(val)
      params

    # poll for updates of the payment, and notify if updates occur
    pollPaymentStatus = (source_id, timeout = 30000, interval = 2000, start = null) ->
      start ||= Date.now()
      $.ajax "/stripe_payments_status/#{source_id}",
        success: (result) ->
          messages = $('div#payment_confirmation_messages')
          switch result['status']
            when 'charge_pending'
              messages.removeClass('processing')
              messages.addClass('success')
            when 'source_failed'
              messages.removeClass('processing')
              messages.addClass('error')
            else
              messages.addClass('processing') unless messages.hasClass('processing')
              if Date.now() < start + timeout
                setTimeout(pollPaymentStatus, interval, source_id, timeout, interval, start)

    params = getParams()
    pollPaymentStatus(params['source'])
